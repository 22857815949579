import * as yup from "yup";

export const options = [
  {
    label: "0",
    value: 0,
  },
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
];

export const defaultValue = {
  0: {
    label: "0",
    value: 0,
  },
  1: {
    label: "1",
    value: 1,
  },
  2: {
    label: "2",
    value: 2,
  },
  3: {
    label: "3",
    value: 3,
  },
  4: {
    label: "4",
    value: 4,
  },
  5: {
    label: "5",
    value: 5,
  },
};

export const getDefaultAnswerValue = (data, id) => {
  if (id === 12) {
    return data?.answer?.value === null
      ? { height: "", weight: "" }
      : data?.answer?.valueList[0];
  }
  return data?.answer?.value
    ? defaultValue[data.answer.value]
    : defaultValue[0];
};

export const getSkinHealthScore = (data, idx, setDetails) => {
  const details = {
    3: {
      modalTitle: "1.How severe is your skin condition as of today?",
      name: "skinCondition",
      placeholder: "How severe is your skin condition as of today?",
      options: options,
      validator: {
        skinCondition: yup.string().required("skinCondition is mandatory!"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    4: {
      modalTitle: "2.How self-conscious do you feel because of your condition?",
      name: "condition",
      placeholder: "How self-conscious do you feel because of your condition?",
      options: options,
      validator: {
        condition: yup.string().required("condition is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    5: {
      modalTitle: "3.How itchy has your skin felt over the past 7 days?",
      name: "itchyCondition",
      placeholder: "How itchy has your skin felt over the past 7 days?",
      options: options,
      validator: {
        itchyCondition: yup.string().required("itchyCondition is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    6: {
      modalTitle:
        "4.How thick have the patches on your skin been over the past 7 days?",
      name: "patches",
      placeholder:
        "How thick have the patches on your skin been over the past 7 days?",
      options: options,
      validator: {
        patches: yup.string().required("patches is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    7: {
      modalTitle:
        "5.How red have the patches on your skin been over the past 7 days?",
      name: "redness",
      placeholder:
        "How red have the patches on your skin been over the past 7 days?",
      options: options,
      validator: {
        redness: yup.string().required("redness is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    8: {
      modalTitle: "6.Work / Education",
      name: "workEducation",
      placeholder: "Work / Education",
      options: options,
      validator: {
        workEducation: yup.string().required("workEducation is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    9: {
      modalTitle: "7.Social Life",
      name: "socialLife",
      placeholder: "Social Life",
      options: options,
      validator: {
        socialLife: yup.string().required("socialLife is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    10: {
      modalTitle: "8.Personal relationships",
      name: "personalRelationships",
      placeholder: "Personal relationships",
      options: options,
      validator: {
        personalRelationships: yup
          .string()
          .required("personal relationships is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    11: {
      modalTitle: "9.Sleep",
      name: "sleep",
      placeholder: "Sleep",
      options: options,
      validator: {
        sleep: yup.string().required("sleep is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
  };

  setDetails({ ...details[idx], data });
};
export const PSORIASIS_DISEASE_ID = "8295d172-f2ee-4596-a35f-9186486b4f16";
export const IBS_DISEASE_ID = "f8c5e4c3-cd3b-4fb6-9209-b4c7067bd400";
export const getPlanNameByDiseaseId = (planName, diseaseId) => {
  const planNameWithDisease = {
    [PSORIASIS_DISEASE_ID]: {
      name: `Psoriasis - ${planName}`,
    },
    [IBS_DISEASE_ID]: {
      name: `IBS - ${planName}`,
    },
  };
  return planNameWithDisease[diseaseId]?.name || "";
};

export const convertCmIntoFeet = (value) => {
  const inches = value * 0.393701;
  const feet = inches / 12;
  return feet.toFixed(2);
};

export const convertFeetIntoCm = (value) => {
  const cm = value * 30.48;
  return cm.toFixed(2);
};

export const leadExclusionConditionStatus = {
  excluded: "Excluded",
  passed: "Passed",
  verification_pending: "Verification Pending",
};

export const leadPrescriptionStatus = {
  verified: "Verified",
  rejected: "Rejected",
  verification_pending: "Verification Pending",
};

export const leadDeliveryStatus = {
  order_received: "Order Received",
  order_confirmed: "Order Confirmed",
  dispatched: "Dispatched",
  delivered: "Delivered",
  returned: "Returned",
};
export const SIDEBAR_ROLES = {
  nutritionist: "nutritionist",
  psychologist: "psychologist",
  "uv coach": "uv coach",
  crm: "crm",
  admin: "admin",
  "psoriasis admin": "back office",
  doctor: "doctor",
  kam: "kam",
  chemist: "chemist",
};

export const leadExclusionConditonStatus = {
  excluded: "Excluded",
  passed: "Passed",
  verification_pending: "Verification Pending",
};

export const leadPrescriptionsStatus = {
  verified: "Verified",
  rejected: "Rejected",
  verification_pending: "Verification Pending",
};

export const formattedAddress = (address) => {
  return `${address?.address},\n ${address?.city} - ${address?.pincode}, ${address?.state}`;
};

export const activityLevels = [
  {
    label: "Sedentary",
    value: "Sedentary (little or no exercise)",
    measure: 1,
  },
  {
    label: "Lightly Active",
    value: "Lightly active (light exercise/sports 1-3 days/week)",
    measure: 2,
  },
  {
    label: "Moderately Active",
    value: "Moderately active (moderate exercise/sports 3-5 days/week)",
    measure: 3,
  },
  {
    label: "Very Active",
    value: "Very active (hard exercise/sports 6-7 days a week)",
    measure: 4,
  },
  {
    label: "Super Active",
    value: "Super active (very hard exercise & physical job or 2x training)",
    measure: 5,
  },
];

export const restrictedDays = [
  {
    label: "Sunday",
    value: "Sunday",
    measure: "0",
  },
  {
    label: "Monday",
    value: "Monday",
    measure: "1",
  },
  {
    label: "Tuesday",
    value: "Tuesday",
    measure: "2",
  },
  {
    label: "Wednesday",
    value: "Wednesday",
    measure: "3",
  },
  {
    label: "Thursday",
    value: "Thursday",
    measure: "4",
  },
  {
    label: "Friday",
    value: "Friday",
    measure: "5",
  },
  {
    label: "Saturday",
    value: "Saturday",
    measure: "6",
  },
  {
    label: "None",
    value: "None",
    measure: "7",
  },
];

export const meatPreferences = [
  {
    label: "Egg",
    value: "Egg",
    measure: 1,
  },
  {
    label: "Chicken",
    value: "Chicken",
    measure: 2,
  },
  {
    label: "Goat",
    value: "Goat",
    measure: 3,
  },
  {
    label: "Beef",
    value: "Beef",
    measure: 4,
  },
  {
    label: "Pork",
    value: "Pork",
    measure: 5,
  },
  {
    label: "Fish",
    value: "Fish",
    measure: 6,
  },
  {
    label: "Other Sea Food",
    value: "Other Sea Food",
    measure: 7,
  },
  {
    label: "Other Poultry",
    value: "Other Poultry",
    measure: 8,
  },
];

export const eatingHabitsMap = {
  vegan: 1,
  veg: 2,
  nonVegetarian: 3,
  eggitarian: 4,
};

export const heightQuestionLabel = "What's your height?";
export const weightQuestionLabel = "What's your weight?";

export const meals = [
  {
    name: "earlyMorning",
    title: "Early Morning",
    value: "earlyMornings",
  },
  {
    name: "breakfast",
    title: "Breakfast",
    value: "breakfast",
  },
  {
    name: "middaySnack",
    title: "Mid Day Snack",
    value: "midDaySnacks",
  },
  {
    name: "lunch",
    title: "Lunch",
    value: "lunch",
  },
  {
    name: "eveningSnack",
    title: "Evening Snack",
    value: "eveningSnacks",
  },
  {
    name: "dinner",
    title: "Dinner",
    value: "dinner",
  },
  {
    name: "bedtime",
    title: "Bedtime",
    value: "bedTime",
  },
  {
    name: "outsideFood",
    title: "Outside Food",
    value: "outsideFood",
  },
];

export const stateCuisineMapping = {
  Andhra: 1,
  Assamese: 2,
  Bengali: 3,
  Bihari: 4,
  Chattisgarhi: 5,
  Goan: 6,
  Gujarati: 7,
  Himachali: 8,
  Jharkhandi: 9,
  Karnataka: 10,
  Kerala: 12,
  Maharashtrian: 13,
  Haryanvi: 14,
  "Madhya Pradesh": 15,
  Nagaland: 16,
  Odiya: 17,
  Punjabi: 18,
  Rajasthani: 19,
  "Tamil Nadu": 20,
  Telangana: 21,
  "Uttar Pradesh": 22,
  Arunachali: 23,
  Manipuri: 24,
  Meghalayan: 25,
  Mizo: 26,
  Sikkimese: 27,
  Tripuri: 28,
  Uttarakhand: 29,
};

export const regionCuisineMapping = {
  Andhra: 4,
  Assamese: 1,
  Bengali: 1,
  Bihari: 1,
  Chattisgarhi: 2,
  Goan: 5,
  Gujarati: 5,
  Himachali: 2,
  Jharkhandi: 2,
  Karnataka: 4,
  Kerala: 4,
  Maharashtrian: 5,
  Haryanvi: 5,
  "Madhya Pradesh": 2,
  Nagaland: 3,
  Odiya: 1,
  Punjabi: 5,
  Rajasthani: 5,
  "Tamil Nadu": 4,
  Telangana: 4,
  "Uttar Pradesh": 2,
  Arunachali: 3,
  Manipuri: 3,
  Meghalayan: 3,
  Mizo: 3,
  Sikkimese: 3,
  Tripuri: 3,
  Uttarakhand: 2,
};

export const localCuisineMapping = {
  Awadhi: 1,
  Chettinad: 2,
  "Coastal Karnataka": 3,
  Kodava: 4,
  Indori: 5,
  Kathiyawadi: 7,
  Konkan: 10,
  Lucknowi: 12,
  Mangalorean: 15,
  Marwari: 16,
  Sindhi: 17,
  Surati: 18,
  Udupi: 19,
  Mughlai: 20,
  Malabari: 21,
  Hyderabadi: 22,
  Amdavadi: 23,
  Kutchi: 24,
  "Uttara Karnataka": 25,
  "Dakshina Karnataka": 26,
  Saraswat: 27,
  Deshastha: 28,
  Vidarbha: 29,
  Khandeshi: 30,
  Garhwali: 31,
  Kumaoni: 32,
};

export const allergyMapping = {
  Dairy: 1,
  Eggs: 2,
  Fish: 6,
  None: 11,
  Nuts: 3,
  Sea_Food: 19,
  Sesame: 8,
  Shell_Fish: 7,
  Soy: 5,
  Tree_Nuts: 4,
  Wheat: 10,
};
export const phases = {
  GUT_CLEANSE: "Gut Cleanse",
  SUSTENANCE: "Sustenance",
  REINTRODUCTION: "Reintroduction",
  ENROLLMENT: "Enrollment",
  FULL_PROGRAM_EXTENSION: "Full Program Extension",
  NUTRITIONIST_SUPPORT_EXTENSION: "Nutritionist support extension",
  PSYCHOLOGY_SUPPORT_EXTENSION: "Psychology support Extension",
};

export const orderCreatedByIconSrc = {
  chemist: {
    src: "/images/icons/chemist-logo.svg",
    title: "Chemist",
    text: "text-success-100",
    border: "border-success-100",
  },
  "psoriasis admin": {
    src: "/images/icons/backoffice-logo.svg",
    title: "Back Office",
    text: "text-secondary-800",
    border: "border-secondary-800",
  },
};
